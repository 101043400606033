import {takeLatest} from 'redux-saga/effects';
import {
  fetchPracticeTestAnswersByStep,
  fetchQuestionnaireAnswersByStep,
  fetchQuestionnaireAnswersByStudent,
  fetchQuestionnaireById,
  postPracticeTestAnswers,
  postQuestionAnswers,
  QuestionnairesTypes,
  fetchPracticeTestFeedback,
} from '../reducers/questionnaires';

const questionnaireSagas = [
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_REQUEST,
    fetchQuestionnaireById,
  ),
  takeLatest(
    QuestionnairesTypes.POST_QUESTION_ANSWER_REQUEST,
    postQuestionAnswers,
  ),
  takeLatest(
    QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_REQUEST,
    postPracticeTestAnswers,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST,
    fetchQuestionnaireAnswersByStep,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST,
    fetchQuestionnaireAnswersByStudent,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_REQUEST,
    fetchPracticeTestAnswersByStep,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_REQUEST,
    fetchPracticeTestFeedback,
  ),
];

export default questionnaireSagas;
