import {
  AdminApiV1QuestionAttemptsGetResponse200,
  AdminQuestionAttempt,
  Questionnaire,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {AxiosResponse} from 'axios';
import {QuestionAnswer} from 'features/useQuestionnaire';
import api from './api';

export const fetchQuestionnaireByIdService = (
  questionnaireId: number,
): Promise<AxiosResponse<Questionnaire>> =>
  api(`/api/v1/questionnaires/${questionnaireId}/`);

export const postQuestionAnswerService = (
  data: Partial<QuestionAnswer>,
  params?: Record<string, number>,
): Promise<AxiosResponse<AdminQuestionAttempt>> =>
  api(`/api/v1/question_attempts/`, {
    method: 'post',
    params,
    data,
  });

export const postPracticeTestAnswerService = (
  data: Partial<QuestionAnswer>,
  params?: Record<string, number>,
): Promise<AxiosResponse<AdminQuestionAttempt>> =>
  api(`/api/v1/question_attempts/practice_test/`, {
    method: 'post',
    params,
    data,
  });

export const fetchQuestionnaireAnswersByStepService = (
  stepId: number | string,
  learningPathUserInstanceId?: number | string,
): Promise<AxiosResponse<AdminApiV1QuestionAttemptsGetResponse200>> =>
  api(`/api/v1/question_attempts/`, {
    params: {
      step: stepId,
      learning_path_user_instance: learningPathUserInstanceId,
    },
  });

export const fetchQuestionAnswersByStudentService = (
  studentId: number,
): Promise<AxiosResponse<AdminApiV1QuestionAttemptsGetResponse200>> =>
  api(`/api/v1/question_attempts/`, {
    params: {
      user: studentId,
    },
  });

export const fetchPracticeTestAnswersByStepService = (
  stepId: number | string,
  learningPathUserInstanceId?: number | string,
): Promise<AxiosResponse<AdminApiV1QuestionAttemptsGetResponse200>> =>
  api(`/api/v1/question_attempts/practice_test/`, {
    params: {
      step: stepId,
      learning_path_user_instance: learningPathUserInstanceId,
      limit: 40,
    },
  });

export const fetchPracticeTestFeedbackService = (
  stepId: number | string,
  learningPathUserInstanceId?: number | string,
): Promise<AxiosResponse<AdminApiV1QuestionAttemptsGetResponse200>> =>
  api(`/api/v1/question_attempts/practice_test_feedback/`, {
    params: {
      step: stepId,
      learning_path_user_instance: learningPathUserInstanceId,
    },
  });

export const postQuestionMatchingService = (data: {
  question_id: number;
  source: 'web' | 'app';
  step_id: number;
  learning_path_user_instance_id: number;
  responses: {pair_id: number; selected_option_id: number}[];
}): Promise<
  AxiosResponse<{
    question_id: number;
    results: {pair_id: number; status: string}[];
    message: string;
  }>
> =>
  api(`/api/v1/question_attempts/matching/`, {
    method: 'post',
    data,
  });

export const getQuestionPairsService = (
  question_id: number,
): Promise<
  AxiosResponse<{
    question_id: number;
    pairs: {
      id: number;
      left_item: string;
      right_option: {id: number; text: string};
    }[];
  }>
> => api(`/api/v1/questions/${question_id}/pairs/`);

export const fetchMatchingResponsesService = (
  question_id: number,
  learning_path_user_instance_id: number,
  step_id: number,
): Promise<
  AxiosResponse<{
    question_id: number;
    responses: {pair_id: number; status: string; selected_option_id: string}[];
  }>
> =>
  api(`/api/v1/question_attempts/matching_responses/`, {
    params: {
      question_id,
      lpui_id: learning_path_user_instance_id,
      step_id,
    },
  });

export const postAiTutorRegisterService = (data: {
  question_id: number;
  source: 'web' | 'app';
  step_id?: number;
  learning_path_user_instance_id?: number;
  ai_tutor_conversation_id: string;
}): Promise<AxiosResponse<any>> =>
  api(`/api/v1/question_attempts/ai_tutor_register/`, {
    method: 'post',
    data,
  });
