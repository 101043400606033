import {
  AdminApiV1QuestionAttemptsPostRequest,
  QuestionAttempt,
  Questionnaire,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {AxiosResponse} from 'axios';
import {QuestionAnswer} from 'features/useQuestionnaire';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';
import {QuestionAttemptExtended} from 'utils/types/questionnaires';
import {
  fetchPracticeTestAnswersByStepService,
  fetchPracticeTestFeedbackService,
  fetchQuestionAnswersByStudentService,
  fetchQuestionnaireAnswersByStepService,
  fetchQuestionnaireByIdService,
  postPracticeTestAnswerService,
  postQuestionAnswerService,
} from '../../services/questionnaireServices';

export interface QuestionAttemptData {
  option?: number;
  feedback?: string;
}

export interface PracticeTestFeedbackData {
  score?: number;
  time_spent?: number;
  level?: number | null;
}

// Action types
export enum QuestionnairesTypes {
  FETCH_QUESTIONNAIRE_BY_ID_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRE_BY_ID_REQUEST',
  FETCH_QUESTIONNAIRE_BY_ID_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRE_BY_ID_SUCCESS',
  FETCH_QUESTIONNAIRE_BY_ID_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRE_BY_ID_FAILURE',

  POST_QUESTION_ANSWER_REQUEST = '@questionnaires/POST_QUESTION_ANSWER_REQUEST',
  POST_QUESTION_ANSWER_SUCCESS = '@questionnaires/POST_QUESTION_ANSWER_SUCCESS',
  POST_QUESTION_ANSWER_FAILURE = '@questionnaires/POST_QUESTION_ANSWER_FAILURE',

  POST_PRACTICE_TEST_ANSWER_REQUEST = '@questionnaires/POST_PRACTICE_TEST_ANSWER_REQUEST',
  POST_PRACTICE_TEST_ANSWER_SUCCESS = '@questionnaires/POST_PRACTICE_TEST_ANSWER_SUCCESS',
  POST_PRACTICE_TEST_ANSWER_FAILURE = '@questionnaires/POST_PRACTICE_TEST_ANSWER_FAILURE',

  FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE',

  FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE',

  FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_REQUEST = '@questionnaires/FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_REQUEST',
  FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_SUCCESS = '@questionnaires/FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_SUCCESS',
  FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_FAILURE = '@questionnaires/FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_FAILURE',

  FETCH_PRACTICE_TEST_FEEDBACK_REQUEST = '@questionnaires/FETCH_PRACTICE_TEST_FEEDBACK_REQUEST',
  FETCH_PRACTICE_TEST_FEEDBACK_SUCCESS = '@questionnaires/FETCH_PRACTICE_TEST_FEEDBACK_SUCCESS',
  FETCH_PRACTICE_TEST_FEEDBACK_FAILURE = '@questionnaires/FETCH_PRACTICE_TEST_FEEDBACK_FAILURE',

  ClEAR_QUESTION_ATTEMPT = '@newQuestionnaires/ClEAR_QUESTION_ATTEMPT',

  FETCH_QUESTION_PAIRS_REQUEST = '@questionnaires/FETCH_QUESTION_PAIRS_REQUEST',
  FETCH_QUESTION_PAIRS_SUCCESS = '@questionnaires/FETCH_QUESTION_PAIRS_SUCCESS',
  FETCH_QUESTION_PAIRS_FAILURE = '@questionnaires/FETCH_QUESTION_PAIRS_FAILURE',

  POST_QUESTION_MATCHING_REQUEST = '@questionnaires/POST_QUESTION_MATCHING_REQUEST',
  POST_QUESTION_MATCHING_SUCCESS = '@questionnaires/POST_QUESTION_MATCHING_SUCCESS',
  POST_QUESTION_MATCHING_FAILURE = '@questionnaires/POST_QUESTION_MATCHING_FAILURE',

  FETCH_MATCHING_RESPONSES_REQUEST = '@questionnaires/FETCH_MATCHING_RESPONSES_REQUEST',
  FETCH_MATCHING_RESPONSES_SUCCESS = '@questionnaires/FETCH_MATCHING_RESPONSES_SUCCESS',
  FETCH_MATCHING_RESPONSES_FAILURE = '@questionnaires/FETCH_MATCHING_RESPONSES_FAILURE',
}

// State type
export interface QuestionnairesState {
  readonly questionnaire: Questionnaire;
  readonly questionnaireAnswers: QuestionAttemptExtended[];
  readonly questionPairs: {
    question_id: number;
    pairs: {
      id: number;
      left_item: string;
      right_option: {id: number; text: string};
    }[];
  } | null;
  readonly matchingResult: {
    question_id: number;
    results: {pair_id: number; status: string}[];
    message: string;
  } | null;
  readonly status: StoreStatus;
  readonly practiceTestFeedback: PracticeTestFeedbackData | null;
}

// Create actions
export const fetchQuestionnaireByIdRequest = (
  questionnaireId: string | number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_REQUEST, {
    questionnaireId,
  });

export const fetchQuestionnaireByIdSuccess = (data: Questionnaire): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_SUCCESS, {data});

export const fetchQuestionnaireByIdFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_FAILURE);

export const postQuestionAnswerRequest = (
  questionAttemptData: Partial<QuestionAnswer>,
  params?: Record<string, number>,
): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_ANSWER_REQUEST, {
    questionAttemptData,
    params,
  });

export const postQuestionAnswerSuccess = (
  data: AdminApiV1QuestionAttemptsPostRequest,
): AnyAction => {
  return action(QuestionnairesTypes.POST_QUESTION_ANSWER_SUCCESS, {
    data,
  });
};

export const postQuestionAnswerFailure = (
  data: AdminApiV1QuestionAttemptsPostRequest,
): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_ANSWER_FAILURE, {data});

export const postPracticeTestAnswerRequest = (
  questionAttemptData: Partial<QuestionAnswer>,
  params?: Record<string, number>,
): AnyAction =>
  action(QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_REQUEST, {
    questionAttemptData,
    params,
  });

export const postPracticeTestAnswerSuccess = (
  data: QuestionAttemptExtended,
): AnyAction => {
  return action(QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_SUCCESS, {
    data,
  });
};

export const postPracticeTestAnswerFailure = (
  data: AdminApiV1QuestionAttemptsPostRequest,
): AnyAction =>
  action(QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_FAILURE, {data});

export const fetchQuestionnaireAnswersByStepRequest = (
  stepId: string | number,
  learningPathUserInstanceId?: string | number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST, {
    stepId,
    learningPathUserInstanceId,
  });

export const fetchQuestionnaireAnswersByStepSuccess = (
  questionnaireAnswers: QuestionAttempt[],
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS, {
    questionnaireAnswers,
  });

export const fetchQuestionnaireAnswersByStepFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE);

export const fetchQuestionnaireAnswersByStudentRequest = (
  studentId: number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST, {
    studentId,
  });

export const fetchQuestionnaireAnswersByStudentSuccess = (
  questionnaireAnswers: QuestionAttempt[],
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS, {
    questionnaireAnswers,
  });

export const fetchQuestionnaireAnswersByStudentFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE);

export const fetchPracticeTestAnswersByStepRequest = (
  stepId: string | number,
  learningPathUserInstanceId?: string | number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_REQUEST, {
    stepId,
    learningPathUserInstanceId,
  });

export const fetchPracticeTestAnswersByStepSuccess = (
  questionnaireAnswers: QuestionAttemptExtended[],
): AnyAction =>
  action(QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_SUCCESS, {
    questionnaireAnswers,
  });

export const fetchPracticeTestAnswersByStepFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_FAILURE);

export const fetchPracticeTestFeedbackRequest = (
  stepId: string | number,
  learningPathUserInstanceId?: string | number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_REQUEST, {
    stepId,
    learningPathUserInstanceId,
  });

export const fetchPracticeTestFeedbackSuccess = (
  questionnaireAnswers: QuestionAttempt[],
): AnyAction =>
  action(QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_SUCCESS, {
    questionnaireAnswers,
  });

export const fetchPracticeTestFeedbackFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_FAILURE);

export const clearQuestionnaireAnswers = (): AnyAction =>
  action(QuestionnairesTypes.ClEAR_QUESTION_ATTEMPT);

export const fetchQuestionPairsRequest = (question_id: number): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTION_PAIRS_REQUEST, {question_id});

export const fetchQuestionPairsSuccess = (data: {
  question_id: number;
  pairs: {
    id: number;
    left_item: string;
    right_option: {id: number; text: string};
  }[];
}): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTION_PAIRS_SUCCESS, {data});

export const fetchQuestionPairsFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTION_PAIRS_FAILURE);

export const postQuestionMatchingRequest = (data: {
  question_id: number;
  responses: {pair_id: number; option_id: number}[];
}): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_MATCHING_REQUEST, {data});

export const postQuestionMatchingSuccess = (data: {
  question_id: number;
  results: {pair_id: number; status: string}[];
  message: string;
}): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_MATCHING_SUCCESS, {data});

export const postQuestionMatchingFailure = (error: any): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_MATCHING_FAILURE, {error});

export const fetchMatchingResponsesRequest = (
  question_id: number,
  learning_path_user_instance_id: number,
  step_id: number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_MATCHING_RESPONSES_REQUEST, {
    question_id,
    learning_path_user_instance_id,
    step_id,
  });

export const fetchMatchingResponsesSuccess = (data: {
  question_id: number;
  responses: {pair_id: number; status: string}[];
  message: string;
}): AnyAction =>
  action(QuestionnairesTypes.FETCH_MATCHING_RESPONSES_SUCCESS, {data});

export const fetchMatchingResponsesFailure = (error: any): AnyAction =>
  action(QuestionnairesTypes.FETCH_MATCHING_RESPONSES_FAILURE, {error});

// Sagas
export function* fetchQuestionnaireById(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<Questionnaire>> {
  try {
    const response = yield call(
      fetchQuestionnaireByIdService,
      action.payload.questionnaireId,
    );

    yield put(fetchQuestionnaireByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireByIdFailure());
  }
}

export function* postQuestionAnswers(
  action: AnyAction,
): Generator<
  StrictEffect,
  void,
  AxiosResponse<AdminApiV1QuestionAttemptsPostRequest>
> {
  try {
    const response = yield call(
      postQuestionAnswerService,
      action.payload.questionAttemptData,
      action.payload.params,
    );

    yield put(postQuestionAnswerSuccess(response.data));
  } catch (error) {
    const err = error as any;
    yield put(postQuestionAnswerFailure(err?.response?.data));
  }
}

export function* postPracticeTestAnswers(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttemptExtended>> {
  try {
    const response = yield call(
      postPracticeTestAnswerService,
      action.payload.questionAttemptData,
      action.payload.params,
    );

    yield put(postPracticeTestAnswerSuccess(response.data));
  } catch (error) {
    const err = error as any;
    yield put(postPracticeTestAnswerFailure(err?.response?.data));
  }
}

export function* fetchQuestionnaireAnswersByStep(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttempt[]>> {
  try {
    const response = yield call(
      fetchQuestionnaireAnswersByStepService,
      action.payload.stepId,
      action.payload.learningPathUserInstanceId,
    );

    yield put(fetchQuestionnaireAnswersByStepSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireAnswersByStepFailure());
  }
}

export function* fetchQuestionnaireAnswersByStudent(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttempt[]>> {
  try {
    const response = yield call(
      fetchQuestionAnswersByStudentService,
      action.payload.studentId,
    );

    yield put(fetchQuestionnaireAnswersByStudentSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireAnswersByStudentFailure());
  }
}

export function* fetchPracticeTestAnswersByStep(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttemptExtended[]>> {
  try {
    const response = yield call(
      fetchPracticeTestAnswersByStepService,
      action.payload.stepId,
      action.payload.learningPathUserInstanceId,
    );

    yield put(fetchPracticeTestAnswersByStepSuccess(response.data));
  } catch (err) {
    yield put(fetchPracticeTestAnswersByStepFailure());
  }
}

export function* fetchPracticeTestFeedback(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttempt[]>> {
  try {
    const response = yield call(
      fetchPracticeTestFeedbackService,
      action.payload.stepId,
      action.payload.learningPathUserInstanceId,
    );

    yield put(fetchPracticeTestFeedbackSuccess(response.data));
  } catch (err) {
    yield put(fetchPracticeTestFeedbackFailure());
  }
}

// Selectors
const questionnairesDataSelector = (state: ApplicationState) =>
  state.get('questionnaires') as QuestionnairesState;

export const getQuestionnaire = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.questionnaire,
);

export const getQuestionnaireAnswers = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.questionnaireAnswers,
);

export const getQuestionnaireStatus = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.status,
);

export const getPracticeTestFeedback = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.practiceTestFeedback,
);

export const getIsPracticeTestFinished = createSelector(
  questionnairesDataSelector,
  (questionnaires) =>
    questionnaires.questionnaireAnswers?.some((answer) => answer?.is_finished),
);

// Initial state
export const INITIAL_STATE: QuestionnairesState = {
  questionnaire: {
    id: -1,
    name: '',
    steps: [],
    created: '',
    modified: '',
  },
  questionnaireAnswers: [],
  questionPairs: null,
  matchingResult: null,
  status: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
    errorMessage: '',
  },
  practiceTestFeedback: null,
};

// Reducer
export const reducer: Reducer<QuestionnairesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_SUCCESS:
      return {
        ...state,
        questionnaire: action.payload.data,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.POST_QUESTION_ANSWER_REQUEST:
      return {
        ...state,
        status: {loading: false, error: false, fulfilled: false, posting: true},
      };

    case QuestionnairesTypes.POST_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: [
          ...state.questionnaireAnswers,
          action.payload.data,
        ],
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.POST_QUESTION_ANSWER_FAILURE:
      return {
        ...state,
        status: {
          loading: false,
          error: true,
          fulfilled: false,
          posting: false,
          errorMessage: action.payload.data?.non_field_errors[0],
        },
      };

    case QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_REQUEST:
      return {
        ...state,
        status: {loading: false, error: false, fulfilled: false, posting: true},
      };

    case QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: [
          ...state.questionnaireAnswers,
          action.payload.data,
        ],
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.POST_PRACTICE_TEST_ANSWER_FAILURE:
      return {
        ...state,
        status: {
          loading: false,
          error: true,
          fulfilled: false,
          posting: false,
          errorMessage: action.payload.data?.non_field_errors[0],
        },
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: action.payload.questionnaireAnswers.results,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: action.payload.questionnaireAnswers.results,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: action.payload.questionnaireAnswers.results,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_PRACTICE_TEST_ANSWERS_BY_STEP_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_SUCCESS:
      return {
        ...state,
        practiceTestFeedback: action.payload.questionnaireAnswers,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_PRACTICE_TEST_FEEDBACK_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTION_PAIRS_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTION_PAIRS_SUCCESS:
      return {
        ...state,
        questionPairs: action.payload.data,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTION_PAIRS_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.POST_QUESTION_MATCHING_REQUEST:
      return {
        ...state,
        matchingResult: null,
        status: {...state.status, posting: true, error: false},
      };

    case QuestionnairesTypes.POST_QUESTION_MATCHING_SUCCESS:
      return {
        ...state,
        matchingResult: action.payload.data,
        status: {...state.status, posting: false, fulfilled: true},
      };

    case QuestionnairesTypes.POST_QUESTION_MATCHING_FAILURE:
      return {
        ...state,
        matchingResult: null,
        status: {
          ...state.status,
          posting: false,
          error: true,
          fulfilled: false,
          errorMessage: action.payload.error?.non_field_errors
            ? action.payload.error.non_field_errors[0]
            : 'Erro ao processar respostas',
        },
      };

    case QuestionnairesTypes.FETCH_MATCHING_RESPONSES_REQUEST:
      return {
        ...state,
        matchingResult: null,
        status: {...state.status, loading: true, error: false},
      };

    case QuestionnairesTypes.FETCH_MATCHING_RESPONSES_SUCCESS:
      return {
        ...state,
        matchingResult:
          action.payload.data.results && action.payload.data.results.length > 0
            ? action.payload.data.results[0]
            : null,
        status: {
          ...state.status,
          loading: false,
          error: false,
          fulfilled: true,
        },
      };

    case QuestionnairesTypes.FETCH_MATCHING_RESPONSES_FAILURE:
      return {
        ...state,
        matchingResult: null,
        status: {
          ...state.status,
          loading: false,
          error: true,
          fulfilled: false,
          errorMessage: action.payload.error?.non_field_errors
            ? action.payload.error.non_field_errors[0]
            : 'Erro ao buscar matching responses',
        },
      };

    case QuestionnairesTypes.ClEAR_QUESTION_ATTEMPT:
      return {
        ...state,
        questionnaireAnswers: [],
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };
    default:
      return state;
  }
};

export default reducer;
